import styled from "styled-components";
import { motion } from "framer-motion";

import { Rem, Colors, BP } from "../../../commons/Theme";

export const StyledBeeSectionTitle = styled(motion.h1)`
  text-align: left;
  font-size: ${Rem(30)};

  margin-bottom: ${Rem(32)};

  @media (${BP.tablet}) {
    text-align: center;
    font-size: ${Rem(40)};
  }
`;

export const StyledWrapperLinkItem = styled(motion.div)`
  display: flex;
  gap: 1.5rem;

  width: 100%;

  overflow-x: scroll;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    visibility: hidden;
  }

  @media (${BP.tablet}) {
    padding-top: ${Rem(20)};
    padding-left: 0;
    padding-right: 0;
    overflow-x: visible;
    gap: 0;
    overflow-y: visible;
    justify-content: space-evenly;

    ::-webkit-scrollbar {
      visibility: visible;
    }
  }
`;

export const StyledWrapperSingleItem = styled(motion.div)`
  display: flex;
  justify-content: center;
  min-width: calc(100% / 3);
  @media (${BP.tablet}) {
    min-width: unset;
  }
`;

export const StyledLinkItem = styled(motion.a)`
  color: ${Colors.yellow};
  font-size: ${Rem(20)};
  padding-bottom: 3px;
  text-decoration: none;
  border-bottom: 2px solid  ${Colors.yellow};
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
  transition: all 0.65s ease-out;
  &:hover {
    color: ${Colors.white};
    border-bottom: 2px solid  ${Colors.white};
  }
  @media (${BP.tablet}) {
    white-space: normal;
  }
`;

export const LinksWrapper = styled(motion.div)`
  position: relative;
  padding: ${Rem(60)} ${Rem(20)};
  color: ${Colors.white};
  background-image: linear-gradient(black, black),
    url("${(props) => props.src}");
  background-size: cover;
  background-position: center;
  background-blend-mode: saturation;
  z-index: 1;

  @media (${BP.tablet}) {
    padding: ${Rem(150)};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    filter: opacity(75%);
    z-index: -1;
  }
`;
